import React, { useEffect, useState } from "react";
import "./styles.css";
import { useApp } from "../../provider/app";
import { Loader } from "../../components/loader";
import { useAuth } from "../../provider/auth";
import { Slider } from "@mui/material";
import axios from "axios";
import { defineApproved } from "../../utils";
import { REACT_APP_URL, URL_NAMES } from "../../utils/state";

// import headerImage from "../../assets/header.png";
// import headerImage2 from "../../assets/header2.png";
import header3 from "../../assets/header3.png";

// import logo from "../../assets/logo.jpg";
// import sign from "../../assets/sign.png";
import QRCode from "qrcode";
import html2pdf from "html2pdf.js";
import { Box } from "@mui/system";
import styled from "@emotion/styled";

import DownloadIcon from "@mui/icons-material/Download";

/* const CustomSlider = styled.Slider`
  & .muislider-rail 
`; */

const CustomSlider = styled(Slider)(() => ({
  "& .MuiSlider-track": {
    height: 13,
    borderRadius: 15,
  },
  "& .MuiSlider-rail": {
    height: 13,
    borderRadius: 15,
  },
  "& .MuiSlider-thumb": {
    display: "none",
  },
}));

export function Certificate() {
  const [codeQR, setCodeQR] = React.useState();

  const [, /* profileData,  */ setProfileData] = React.useState([]);
  const [, /* pendingModules */ setPendingModules] = React.useState([]);
  const [, /* approvedModules */ setApprovedModules] = React.useState();
  const [resultsDetail, setResultsDetail] = useState(null);
  const [parsed, setParsed] = useState(null);
  const [descDetail, setDescDetail] = useState(null);
  const [, /* modules */ setModules] = React.useState(null);
  const { app, appActions } = useApp();
  const { auth } = useAuth();
  const modules_dictionary = {
    ina0: "Predicción de riesgo",
    ina1: "Identificación de riesgo",
    ina2: "Evaluación psicológica",
  };
  // const modulos = ['quest','inatrans-identificacion-de-riesgos','inatrans-evaluacion-psicologica']
  const trackingURL = "https://tipconsola.iapp.cl/api/certified/tracking";
  const headers = {
    "Content-Type": "application/json",
  };
  const [isApproved, setApproved] = React.useState(false);

  const parse = (str) => JSON.parse(JSON.parse(str));

  function arrayReducer_module_name(a) {
    let output = [];
    a.forEach((element) => {
      let cleanString = parse(element.module_results);
      output.push(cleanString.module_name);
    });
    return output;
  }
  function arrayReducer_results(a) {
    let output = [];
    let tempmodules = [];
    a.forEach((element) => {
      // console.log({ element });
      let cleanString = parse(element.module_results);
      /*       console.log("cleanstring:", cleanString); */
      // console.log({ cleanString });
      output.push(cleanString.results);
      tempmodules.push(cleanString);
    });
    setModules(tempmodules);
    return output;
  }

  function checkApprovedModules(a) {
    let counter = 0;
    let res = arrayReducer_results(a);
    res.forEach((e) => {
      if (e === "Cumple") {
        counter++;
      }
    });
    setApprovedModules(counter);
    // console.log({ res });
    final(res);
    return counter;
  }

  function final(a) {
    let final = defineApproved({
      name: URL_NAMES.CERTIFICATE,
      final: a,
    });
    setApproved(final);
    // console.log({ final });
  }

  function compareArray(a, o) {
    let keys = Object.keys(o);
    let current = arrayReducer_module_name(a);
    let result = keys.filter((x) => !current.includes(x));

    return result;
  }

  function finding(a, o) {
    let res = compareArray(a, o);
    let value = [];
    res.forEach((e) => value.push(modules_dictionary[`${e}`]));
    setPendingModules(value);
  }

  function selectHigherResultsOfEachModule(a) {
    console.log({ a });
    let arraySorted = a.sort(function (a, b) {
      return b.parsedResults.correct - a.parsedResults.correct;
    });
    let arrayBestResults = [];
    // let module_index = 0;
    console.log({ arraySorted });
    for (let i = 0; i < 3; i++) {
      // console.log("indice i:", i);
      for (let j = 0; j < arraySorted.length; j++) {
        // console.log("indice j:", j);
        if (arraySorted[j].parsedResults.module_name === `ina${i}`) {
          arrayBestResults = [...arrayBestResults, arraySorted[j]];
          j = arraySorted.length;
        }
      }
    }
    console.log({ arrayBestResults });
    return arrayBestResults;
  }

  const getProfileData = async () => {
    try {
      const { data } = await axios.post(
        trackingURL,
        {
          client: auth.cookies.config,
          provider: "youtube",
          course_id: auth.cookies.course_id,
          user_id: auth.cookies.user_id,
        },
        { headers }
      );
      // console.log({ data });
      if (data === "activity does not exist!") {
        // setPendingModules([modules_dictionary.ina0, modules_dictionary.ina1, modules_dictionary.ina2])
        setProfileData([1, 2, 3]);
        setApprovedModules(3);
        // console.log(profileData);
      } else {
        let modules = [];
        // let newdata = data;
        let newdata = null;
        // if (data.length > 3) {
        //   newdata = data.reverse().slice(0, 3);
        //   console.log({ newdata });
        // }

        // let parseData = newdata.map((val) => ({
        //   ...val,
        //   parsedResults: JSON.parse(JSON.parse(val.module_results)),
        // }));
        let parseData = data.map((val) => ({
          ...val,
          parsedResults: JSON.parse(JSON.parse(val.module_results)),
        }));
        let finalData = selectHigherResultsOfEachModule(parseData);
        newdata = finalData;
        // let psychoModule = parseData.filter(
        //   (module) => module.url_name === "inatrans-evaluacion-psicologica"
        // )[0];
        let psychoModule = finalData.filter(
          (module) => module.url_name === "inatrans-evaluacion-psicologica"
        )[0];

        if (psychoModule) {
          setDescDetail(psychoModule.parsedResults.description_range);
          setResultsDetail(psychoModule.parsedResults.results_detail);
        }

        // console.log({ parseData });

        // setParsed(parseData);
        setParsed(finalData);

        newdata = newdata.filter((e) => e.module_results);
        newdata.forEach((e, idx) => {
          modules.push(e);
        });

        // console.log({ modules });
        checkApprovedModules(newdata);
        finding(modules, modules_dictionary);
        setProfileData(newdata);
      }
      return data;
    } catch (err) {
      console.log({ err });
      return false;
    }
  };

  React.useEffect(() => {
    let c = auth.cookies;

    generateQR(
      `${REACT_APP_URL.PROD}?course_id=${c.course_id}&course_name=${c.course_name}&user_id=${c.user_id}&user_name=${c.user_name}&user_email=${c.user_email}&url_cmid=${c.url_cmid}&url_name=certificate&config=${c.config}&isApproved=${c.isApproved}`
    );
    getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // resultsDetail && console.log(resultsDetail);
    // descDetail && console.log([descDetail]);
    //eslint-disable-next-line
  }, [resultsDetail, descDetail]);

  const generateQR = async (text) => {
    try {
      let imgData = await QRCode.toDataURL(text);
      // console.log(imgData);
      setCodeQR(imgData);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownload = (page1ID, page2ID) => {
    // Get the two HTML elements
    const page1 = document.querySelector("#page-1");
    const page2 = document.querySelector("#page-2");

    // Add page 1 to the PDF
    // Create a container element to hold both pages
    const container = document.createElement("div");
    container.appendChild(page1.cloneNode(true));
    container.appendChild(page2.cloneNode(true));

    // Convert the container to a PDF
    html2pdf()
      .set({ pagebreak: { avoid: ".pagebreak" } })
      .from(container)
      .save();
  };

  if (!descDetail) {
    return null;
  }

  return (
    <>
      {app.loading ? (
        <Loader app={app} appActions={appActions} />
      ) : (
        <>
          <div
            style={{
              position: "fixed",
              bottom: 50,
              right: 50,
              background: "#60a5fa",
              width: 50,
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <DownloadIcon
              sx={{ color: "#fff", fontSize: 30 }}
              onClick={handleDownload}
            />
          </div>
          <div
            className="certificado container"
            style={{
              position: "absolute",
              top: 60,
              padding: 0,
              width: "900px",
            }}
          >
            <div
              id="page-1"
              style={{
                padding: "2rem 3rem",
                width: "100%",
                height: "1121px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img alt="..." src={header3} width="90%" height={"100%"} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 30,
                }}
              >
                <div>
                  <p style={{ color: "#000", fontWeight: 500, fontSize: 20 }}>
                    Nombre
                  </p>
                  <p style={{ color: "grey", fontWeight: 400, fontSize: 18 }}>
                    {decodeURIComponent(auth.user.user_name)}
                  </p>
                </div>
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <p style={{ color: "#000", fontWeight: 500, fontSize: 20 }}>
                    Fecha de evaluacion
                  </p>
                  <p style={{ color: "grey", fontWeight: 400, fontSize: 18 }}>
                    {new Date().toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <div>
                  <p style={{ color: "#000", fontWeight: 500, fontSize: 20 }}>
                    Categoria
                  </p>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: 18,
                      color: isApproved === "Aprobado" ? "green" : "red",
                    }}
                  >
                    {isApproved}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <div>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: 15,
                      lineHeight: 1.3,
                      color: "#000",
                    }}
                  >
                    La presente evaluación de prevencion de riesgo de conduccion
                    realizada por Inatrans, certifica que el trabajador en
                    referencia, se le aplico el examen e evaluando sus
                    competencias en control de riesgos, evaluacion de riesgos y
                    evaluacion psicologica.
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <div>
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: 18,
                      lineHeight: 1.3,
                      color: "#000",
                    }}
                  >
                    Actividades
                  </p>
                </div>
              </div>

              {parsed &&
                parsed.length > 0 &&
                parsed.map((module, i) => {
                  if (module.parsedResults.module_name === "ina0") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 20,
                        }}
                        key={i}
                      >
                        <div>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: 18,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Control de riesgos
                          </p>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            {module.parsedResults.results}
                          </p>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Respuestas correctas: {module.parsedResults.correct}
                          </p>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Respuestas incorrectas:{" "}
                            {module.parsedResults.incorrect}
                          </p>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Total preguntas: {module.parsedResults.totalanswers}
                          </p>
                        </div>
                      </div>
                    );
                  }
                  if (module.parsedResults.module_name === "ina1") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 20,
                        }}
                        key={i}
                      >
                        <div>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: 18,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Evaluacion de riesgos
                          </p>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            {module.parsedResults.results}
                          </p>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Respuestas correctas: {module.parsedResults.correct}
                          </p>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Respuestas incorrectas:{" "}
                            {module.parsedResults.incorrect}
                          </p>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Total preguntas: {module.parsedResults.totalanswers}
                          </p>
                        </div>
                      </div>
                    );
                  }
                  if (module.parsedResults.module_name === "ina2") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 20,
                        }}
                        key={i}
                      >
                        <div>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: 18,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Evaluacion de psicologica
                          </p>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            {module.parsedResults.results}
                          </p>

                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: 16,
                              lineHeight: 1.3,
                              color: "#000",
                            }}
                          >
                            Total preguntas: {module.parsedResults.totalanswers}
                          </p>
                        </div>
                      </div>
                    );
                  } else {
                    return <div key={i}></div>;
                  }
                })}

              <div style={{ marginTop: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    {codeQR && (
                      <img
                        src={codeQR}
                        className="qr"
                        alt="código QR"
                        style={{ height: 150, width: 150 }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        color: "#b2b2b2",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      Documento confidencial
                    </p>
                    <p
                      style={{
                        color: "#b2b2b2",
                        fontSize: 10,
                        fontWeight: 300,
                        lineHeight: 1,
                        marginTop: 5,
                      }}
                    >
                      Este documento es confidencial y solo puedene tener acceso
                      a él los encargados de la evaluacion y profesionales afin.
                    </p>
                    <p
                      style={{
                        color: "#b2b2b2",
                        fontSize: 10,
                        fontWeight: 300,
                        lineHeight: 1,
                      }}
                    >
                      Por motivos de confidencialidad, si desea aclarar alguna
                      información contacte a Inatrans
                    </p>
                  </div>
                  <div style={{ width: "50%" }}>
                    <p
                      style={{
                        color: "#b2b2b2",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      Inatrans | Condel 115, Providencia, Teléfonos 22 2748646
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="page-2"
              style={{
                padding: "2rem 3rem",
                width: "100%",
                height: "1100px",
                display: "flex",
                flexDirection: "column",
                borderColor: "#000",
                borderTop: "solid",
                borderWidth: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img alt="..." src={header3} width="90%" height={"100%"} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 60,
                }}
              >
                {descDetail.extDesc?.map((element, idx) => {
                  if (idx === 1 && !isNaN(element)) {
                    return (
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                        key={idx}
                      >
                        <Box
                          sx={{
                            width: 250,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#000",
                              fontSize: 10,
                              fontWeight: 400,
                              marginRight: 7,
                              marginBottom: 15,
                            }}
                          >
                            0
                          </p>
                          <CustomSlider
                            disabled
                            aria-label="Small steps"
                            defaultValue={element}
                            step={10}
                            marks
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                          />
                          <p
                            style={{
                              color: "#000",
                              fontSize: 10,
                              fontWeight: 400,
                              marginLeft: 7,
                              marginBottom: 15,
                            }}
                          >
                            100
                          </p>
                        </Box>
                        <p
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 600,
                            opacity: 0.6,
                            transform: "translateY(-2px)",
                          }}
                        >
                          {element}
                        </p>
                      </div>
                    );
                  }
                  return (
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      key={idx}
                    >
                      <p
                        style={{
                          color: "#000",
                          fontWeight: 500,
                          lineHeight: 1.1,
                          fontSize: 16,
                          textAlign: "center",
                        }}
                      >
                        {element}
                      </p>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 40,
                }}
              >
                {descDetail.neuDesc?.map((element, idx) => {
                  if (idx === 1 && !isNaN(element)) {
                    return (
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                        key={idx}
                      >
                        <Box
                          sx={{
                            width: 250,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#000",
                              fontSize: 10,
                              fontWeight: 400,
                              marginRight: 7,
                              marginBottom: 15,
                            }}
                          >
                            0
                          </p>
                          <CustomSlider
                            disabled
                            aria-label="Small steps"
                            defaultValue={element}
                            step={10}
                            marks
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                          />
                          <p
                            style={{
                              color: "#000",
                              fontSize: 10,
                              fontWeight: 400,
                              marginLeft: 7,
                              marginBottom: 15,
                            }}
                          >
                            100
                          </p>
                        </Box>
                        <p
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 500,
                            opacity: 0.6,
                            transform: "translateY(-2px)",
                          }}
                        >
                          {element}
                        </p>
                      </div>
                    );
                  }

                  return (
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      key={idx}
                    >
                      {" "}
                      <p
                        style={{
                          color: "#000",
                          fontWeight: 500,
                          fontSize: 16,
                          lineHeight: 1.1,
                          textAlign: "center",
                        }}
                      >
                        {element}
                      </p>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 40,
                }}
              >
                {descDetail.psiDesc?.map((element, idx) => {
                  if (idx === 1 && !isNaN(element)) {
                    return (
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                        key={idx}
                      >
                        <Box
                          sx={{
                            width: 250,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#000",
                              fontSize: 10,
                              fontWeight: 400,
                              marginRight: 7,
                              marginBottom: 15,
                            }}
                          >
                            0
                          </p>
                          <CustomSlider
                            disabled
                            aria-label="Small steps"
                            defaultValue={element}
                            step={10}
                            marks
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                          />
                          <p
                            style={{
                              color: "#000",
                              fontSize: 10,
                              fontWeight: 400,
                              marginLeft: 7,
                              marginBottom: 15,
                            }}
                          >
                            100
                          </p>
                        </Box>
                        <p
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 500,
                            opacity: 0.6,
                            transform: "translateY(-2px)",
                          }}
                        >
                          {element}
                        </p>
                      </div>
                    );
                  }
                  return (
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      key={idx}
                    >
                      {" "}
                      <p
                        style={{
                          color: "#000",
                          fontWeight: 500,
                          lineHeight: 1.1,
                          fontSize: 16,
                          textAlign: "center",
                        }}
                      >
                        {element}
                      </p>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 40,
                }}
              >
                {descDetail.eleDesc?.map((element, idx) => {
                  if (idx === 1 && !isNaN(element)) {
                    return (
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                        key={idx}
                      >
                        <Box
                          sx={{
                            width: 250,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#000",
                              fontSize: 10,
                              fontWeight: 400,
                              marginRight: 7,
                              marginBottom: 15,
                            }}
                          >
                            0
                          </p>
                          <CustomSlider
                            disabled
                            aria-label="Small steps"
                            defaultValue={element}
                            step={10}
                            marks
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                          />
                          <p
                            style={{
                              color: "#000",
                              fontSize: 10,
                              fontWeight: 400,
                              marginLeft: 7,
                              marginBottom: 15,
                            }}
                          >
                            100
                          </p>
                        </Box>
                        <p
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 500,
                            opacity: 0.6,
                            transform: "translateY(-2px)",
                          }}
                        >
                          {element}
                        </p>
                      </div>
                    );
                  }
                  return (
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      key={idx}
                    >
                      {" "}
                      <p
                        style={{
                          color: "#000",
                          fontWeight: 500,
                          fontSize: 16,
                          textAlign: "center",
                        }}
                      >
                        {element}
                      </p>
                    </div>
                  );
                })}
              </div>

              {resultsDetail &&
                resultsDetail.length > 0 &&
                resultsDetail.map((detail, idx) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 30,
                      }}
                      key={idx}
                    >
                      <div>
                        <p
                          style={{
                            color: "#000",
                            fontSize: 15,
                            fontWeight: 500,
                          }}
                        >
                          {detail.title}
                        </p>
                        <p
                          style={{
                            color: "#000",
                            fontSize: 15,
                            fontWeight: 300,
                          }}
                        >
                          {detail.value}
                        </p>
                      </div>
                    </div>
                  );
                })}

              <div style={{ marginTop: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        color: "#b2b2b2",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      Documento confidencial
                    </p>
                    <p
                      style={{
                        color: "#b2b2b2",
                        fontSize: 10,
                        fontWeight: 300,
                        lineHeight: 1,
                        marginTop: 5,
                      }}
                    >
                      Este documento es confidencial y solo puedene tener acceso
                      a él los encargados de la evaluacion y profesionales afin.
                    </p>
                    <p
                      style={{
                        color: "#b2b2b2",
                        fontSize: 10,
                        fontWeight: 300,
                        lineHeight: 1,
                      }}
                    >
                      Por motivos de confidencialidad, si desea aclarar alguna
                      información contacte a Inatrans
                    </p>
                  </div>
                  <div style={{ width: "50%" }}>
                    <p
                      style={{
                        color: "#b2b2b2",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      Inatrans | Condel 115, Providencia, Teléfonos 22 2748646
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/*            <div
              style={{
                borderColor: "#000",
                borderTop: "solid",
                borderWidth: 5,
              }}
            > */}
            {/*               <div className="header-cert">
                <div className="datos-perfil">
                  <div className="foto-perfil">
                    <Avatar className="img alt="..."-perfil">
                      {auth.user.user_name[0].toUpperCase()}
                    </Avatar>
                  </div>
                  <h1>{auth.user.user_name}</h1>
                </div>
                <div className="filete"></div>
                <div className="puntaje">
                  <div className="row text-center">
                    <div className="col-6">
                      <span className="numeros">{profileData.length}/3</span>
                      <br></br>
                      Módulos
                    </div>
                    <div className="col-6">
                      <span className="numeros">
                        {approvedModules ? approvedModules : 0}
                      </span>{" "}
                      <br></br>
                      Aprobados
                    </div>
                  </div>
                </div>
              </div> */}
            {/* 
              <div className="fil"></div> */}

            {/*     <div className="contenido">
                {profileData.length < 3 && (
                  <div className="pending">
                    <h2>Módulos Pendientes</h2>
                    {pendingModules.map((e, idx) => {
                      return (
                        <div
                          key={idx}
                          className="row mt-3 box-shadow align-items-center"
                        >
                          <div className="col-10">
                            <h4 className="modulo-pendiente">{e}</h4>
                            <p className="bajada-modulo">módulo pendiente</p>
                          </div>
                          <div className="col-2"></div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {profileData.length === 3 && (
                  <>
                    <div className="bloque-certificado">
                      <h2 className="titulo-certificado">Tu Certificado</h2>
                      <div className="row mt-3 box-shadow align-items-center ">
                        <div className="col-10 bajada-modulo">
                          <ul>
                            {modules &&
                              modules.map((m, idx) => {
                                return (
                                  <li
                                    style={{
                                      listStyle: "none",
                                      display: "flex",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                    >
                                      - {modules_dictionary[m.module_name]}
                                    </p>
                                    <p
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                      }}
                                    >
                                      : {m.results}
                                    </p>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        {isApproved ? (
                          <div
                            className="col-2 certificate-img alt="...""
                            onClick={() => handleNavigation()}
                          >
                            <img alt="..."
                              style={{ cursor: "pointer" }}
                              src={require("../../assets/certificado-22.jpg")}
                              alt="miniatura certificado"
                            />
                          </div>
                        ) : (
                          <div
                            className="col-2 certificate-img alt="...""
                            onClick={() => handleNavigation()}
                          >
                            <button className="btn disabled btn-outline-danger">
                              Reprobado
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {descDetail && JSON.stringify(descDetail)}
                <p>hola</p>
                {resultsDetail && JSON.stringify(resultsDetail)}
              </div> */}
            {/*      </div> */}
          </div>
        </>
      )}
    </>
  );
}
